.search-header-result {
  position: absolute;
  top: 100%;
  right: -1px;
  left: -1px;
  margin-top: 0;
  background-color: color(background-white);
  border: 1px solid color(search-border);

  @include media(small) {
    margin-top: 1px;
  }
}

.search-header-result__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.search-header-result__item {
  border-bottom: 1px solid color(search-border);
}

.search-header-result__link {
  display: block;
  padding: measure(half) measure(full);
  text-decoration: none;
}

.search-header-result__link--all-results {
  padding: measure(full);
}
