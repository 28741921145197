.endUser{
    .option{
        display: flex;
        flex-direction: column;
        .sub{
            @include font-size(body-small);;
            .header{
                font-weight: bold;
                background-color: transparent;
            }
        }
    }
}