@mixin export-map($map, $name: '', $stringify: false) {
  :export {
    @each $key, $value in $map {
      @if $value != null {
        @if ($stringify) {
           #{$key}: #{inspect($value)};
        } @else if (str-length($name) > 0) {
           #{$name}-#{$key}: #{$value};
        } @else {
          #{$key}: #{$value};
        }
      }
    }
  }
 }
 