.welcome-information {
  background-color: color(background-white);
}

.welcome-information__container {
  @include container;
  display: flex;
  flex-direction: column;
  padding-top: measure(full);
  padding-bottom: measure(full);

  @include media(small) {
    flex-direction: row;
    padding-top: measure(quad);
    padding-bottom: measure(quad);
  }
}

.welcome-information__header {
  @include h1;
  margin-top: 0;
  margin-bottom: measure(full);

  @include media(small) {
    margin-bottom: 0;
  }
}

.welcome-information__help {
  @include body;
  
  @include media(small) {
    align-self: flex-end;
    margin-left: auto;
  }
}

.welcome-information__help-link {
  @include reset-link;
  display: block;
  margin-top: measure(eighth);
  text-transform: uppercase;
}
