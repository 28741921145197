.figure-modal-container{
    width: auto;
    top: 1rem;
    max-height: calc(100vh - 1rem);
    .figure-modal__header{
        justify-content: space-between;
        padding-left: measure(quad);
        h2{
            text-transform: uppercase;
            @include media(to-small) {
                font-size: 16px;
            }
        }
    }
    
    .figure-modal__body{
        display:flex;
        padding: measure(half);
        justify-content: space-between;
        flex-direction: column;
    }

    .figure-modal__footer{
        display:flex;
        justify-content: space-between;
        padding: measure(half);
        @include media(to-large) {
            flex-direction: column;
        }
        
        &-inputs{
            display: flex;
            justify-content: space-between;
            @include media(to-small) {
                flex-direction: column;
            }
            flex-grow: 1;
            .componentContainer{
                flex-grow: 2;
                padding-right:measure(half);
            }
            .componentContainer:first-child{
                flex-grow: 1;
            }
        }

        &-buttons{
            display: flex; 
            justify-content: flex-end;
            flex-grow: 0;
            button{
                margin: auto measure(half) auto 0;
            }
            @include media(to-large) {
                margin-top: measure(half);
            }
        }
        
    }
}

