.upload-modal-container{
    width: auto;
    top: 1rem;
    max-height: calc(100vh - 1rem);
    .upload-modal__header{
        justify-content: space-between;
        padding-left: measure(quad);
        h2{
            text-transform: uppercase;
            @include media(to-small) {
                font-size: 16px;
            }
        }
    }
    
    .upload-modal__body{
        display:flex;
        padding: measure(half);
        justify-content: space-between;
        flex-direction: column;

        width: 80vw;
        height: 60vh;
        @include media(large) {
            width: 60vw;
        }

        .dropzone{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin:0;
            padding: 10px;
            min-height:auto;
            overflow-y: scroll;
            overflow-x: hidden;
            flex-wrap: wrap;
            height: 100%;
            background-color: color( modal-header-background);
            .preview {
                width: 48%;
                @include media(large) {
                    width: 32%;
                }
                @include media(to-small){
                    width: 100%;
                }
                border:none;
                margin-right: 10px;
                margin-bottom: 20px;
                padding:0;
                position: relative;

                .preview-container{
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .preview-overlay{
                        position:absolute;
                        display: flex;
                        left:0;
                        width:100%;
                        justify-content: center;
                        bottom: 0;
                        margin-bottom: 10px;
                        button{
                            margin-right: 10px;
                        }
                    }
                    .preview-loader{
                        height: 34vh;
                        @include media(large) {
                            height: 26vh;
                        }
                        @include media(to-small){
                            height: 38vh;
                        }
                        width: 100%;
                    }
                    .preview-image{
                        width: auto;
                        max-height: 34vh;
                        @include media(large) {
                            max-height: 26vh;
                        }
                        @include media(to-small){
                            max-height: 60vh;
                        }
                        max-width: 100%;
                    }
                    .preview-uploading-overlay{
                        height: 34vh;
                        @include media(large) {
                            height: 26vh;
                        }
                        @include media(to-small){
                            height: 60vh;
                        }
                        position:absolute;
                        display: flex;
                        left:0;
                        width:100%;
                        backdrop-filter: blur(5px);
                    }
                    .preview-uploaded-overlay{
                        position:absolute;
                        display: flex;
                        left:0;
                        width:100%;
                        justify-content: center;
                    }
                }    
            }

            .dropzone-inputLabel{
                @include h2-medium;
                color: color(text);
                text-align: center;
                text-align: center;
                width: 100%;
                margin: auto 0;
            }
        }
    }

    .upload-modal__footer{
        display:flex;
        justify-content: center;
        padding: measure(half);
        @include media(to-large) {
            flex-direction: column;
        }
    }
}




    