.settings-menu{
    background-color: color(green-1);
    flex-direction:column;
    display:flex;
    position: absolute;
    top:30px;
    right: -7px;
    border-radius: 10px;
    z-index: 11;
    //width:445px;
    width:350px;
    height: auto;
    padding: measure(full) measure(full) ;
    &__action{
        padding: 0 0;
        .checkbox{
            margin-top:measure(quarter);
            .checkbox__label{
                height:auto;
                .checkbox__label-span{
                    color: color(gray-6);
                }
            }
        }
    }
}