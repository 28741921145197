.application-feedback {
  height: 100%;

  @include media(small) {
    position: relative;
  }
}

.application-feedback__toggler {
  @include reset-button;
  width: 100%;
  height: 100%;
  padding: measure(quarter);
  background-color: transparent;
  border: none;
  color: color(text-inverted);
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    background-color: color(nav-active-background);
  }

  @include media(large) {
    width: auto;
    height: 4.375rem;
    padding: 0 measure(full);
  }
}

.application-feedback__toggler--active {
  background-color: color(nav-active-background);
  margin-bottom: 0;
}

.application-feedback__toggler-svg-arrow {
  margin-left: auto;
  transition: transform 140ms;

  @include media(large) {
    margin-left: measure(quarter);
  }
}

.application-feedback__toggler--active .application-feedback__toggler-svg-arrow {
  transform: scaleY(-1);
}

.application-feedback__navigation-items {
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: color(nav-active-background);

  @include media(to-small) {
    left: 0
  }

  @include media(large) {
    padding-top: measure(double);
  }
}

.application-feedback__link {
  display: flex;
  align-items: center;
  color: color(text-inverted);
  background-color: transparent;
  border-bottom: 1px solid color(nav-border);
  height: 3rem;
  padding: 0 measure(full);
  white-space: nowrap;
  text-decoration: none;

  &:hover {
    background-color: color(nav-background);
  }

  @include media(large) {
    padding: 0 measure(double);

    &:first-child {
      border-top: 1px solid color(nav-border);
    }
  }
}
