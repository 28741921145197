.navigation-toggler {
  background-color: transparent;
  border: none;
  padding: measure(quarter);
  margin: 0;

  &:hover {
    cursor: pointer;
  }
}

.burger {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 1.375rem;
  height: 1rem;
  vertical-align: middle;
}

.burger .burger__row {
  width: 100%;
  height: 2px;
  transition: all 0.18s cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  background-color: white;
}

.navigation-toggler--is-active .burger__row--1 {
  transform: translateY(7px) translateX(0) rotate(45deg);
}

.navigation-toggler--is-active .burger__row--2 {
  opacity: 0;
  transition: all 0s;
}

.navigation-toggler--is-active .burger__row--3 {
  transform: translateY(-7px) translateX(0) rotate(-45deg);
}
