.ai-modal-container{
    width: 1200px;
    @include media(to-large) {
        width: 100vw;
        
    }    
    .ai-canvas-include{
       display:none;
    }
    overflow: auto;
}

.ai-modal-zoom-container{
    width: auto;
    
    @include media(to-large) {
        width: auto;
    }    
    .ai-modal-zoom__body{
        padding: measure(quarter); 
        .image{
            img{
                height: 80vh;
            }
            .react-modal__close-button{
                position:absolute;
                top:40px;
                right:40px;
            }
        }
    }
}

.ai-modal__header{
    justify-content: space-between;
    padding-left: measure(quad);
    margin-bottom: measure(half);
    h2{
        font-weight: normal;
        @include media(to-small) {
            font-size: 16px;
        }
    }
}

.ai-modal__body{
    & > *:not(:last-child){
        margin-bottom: measure(half);
    }
    @include media(to-large) {
        padding: measure(quarter);
    }
    display: flex;
    align-items: flex-start;
    padding-top: 0;
    flex-wrap: wrap;
    
    .ai-header{
        display: flex;
        justify-content: space-between;    
        width:100%;
        p{
            width: 100%;
            margin: 0;
            @include media(to-small) {
                font-size: 16px;
            }
        }
    }

    .ai-container{
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        width:100%;
        @include media(to-large) {
            flex-direction: column-reverse;
        }
        .ai-image{
            display:block;
            width:25%;
            @include media(to-large) {
                width:100%;
                margin-top: 20px;
                & > div{
                    display:flex;
                    justify-content: center;
                }
            }
            img {
                margin:auto auto;
            }
    
            .overlay{
                position: absolute;
                left:0;
                top:0;
                width:100%;
                height: 100%;
                cursor: pointer;
    
                svg{
                    background: transparent;
                    right: 6px;
                    position: absolute;
                    top: 6px;   
    
                }
            }
    
            .zoom-container{
                position: absolute;
                left:0;
                top:0;
                width:70%;
                height: 100%;
                
            }
            .checkbox{
                margin-top:measure(half);
            }
        }
    
        .ai-predictions {
            display: flex;
            flex-wrap: wrap;
            width: 75%;
            padding-left: measure(double);
            
            @include media(to-large) {
                width: 100%;
                padding-left:0;
            }
    
            &__row{
                display:flex;
                flex-direction: row;
                width:100%;
                border-bottom: 1px solid color(gray-4);
                .ai-center-column{
                    text-align: center;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                }
                .ai-header-column{
                    font-weight: bold;
                    padding-bottom: measure(quarter);
                    padding-top: 0;
                }
                &.selected{
                    background-color: color(gray-6);
                }
            }
            
            &__column{
                width: calc((100% - 60%)/2);
                padding: measure(half) 0;
                text-transform: capitalize;
                &:first-of-type{
                    width: 30%;
                    padding-left: calc(1.5625rem + 8px);
                }   
                
            }
            .ai-center-confirmation{
                display:flex;
                flex-wrap:wrap;
                width: 30%;
                padding-left: calc(1.5625rem);
                @include media(to-large) {
                    justify-content: flex-start;
                }
                & > div:first-of-type{
                    padding-right:measure(half);
                }
            }
            .prediction-item__color {
                padding-left: calc(1.5625rem);
                border-left: solid 8px white;
            }
            .prediction-item-feedback{
                width:100%;
                & > div{
                    margin-top: measure(quarter);
                }
            }
            .ai-predictions_reset{
                display:flex;
                justify-content: flex-end;
                width: 100%;
                button{
                    cursor:pointer;
                    display: flex;
                    align-items: center;
                    height: 2.5rem;
                    padding: 0 measure(full);
                    color: color(blue-1);
                    background-color: transparent;
                    border: none;
                    white-space: nowrap;
                    svg{
                        height:50%;
                        padding-right:measure(quarter);
                    }
                }
                &.reset-disabled{
                    button{
                        color: color(gray-2);
                        cursor:default;
                    }
                }
            }
        }
    }
    

    .ai-actions{ 
        display: flex;

        justify-content: space-between;    
        width:100%;
        div{
            .button{
                margin-left:10px;
            }
            display:flex;
        }
    }
}

.radio {
    display: flex;
    align-items: center;
  }
  
  .radio__disabled {
    opacity: 0.5;
  }
  
  .radio__hidden {
    display: none;
  }
  .radio__label {
    @include body-light;
    position: relative;
    display: flex;
    align-items: center;
    height: 1.5rem;
    padding-left: calc(1.5rem + #{measure(eighth)});
  
    &:hover {
      cursor: pointer;
    }
  
    &:before {
        content: "";
        -webkit-box-align: center;
        align-items: center;
        background-color: rgb(255, 255, 255);
        border: 1px solid rgb(170, 175, 184);
        border-radius: 50%;
        display: flex;
        height: 1.5rem;
        -webkit-box-pack: center;
        justify-content: center;
        left: 0px;
        pointer-events: none;
        position: absolute;
        top: 0px;
        width: 1.5rem;
    }
  
    &:after {
        content: "";
        -webkit-box-align: center;
        align-items: center;
        background-color: rgb(255, 255, 255);
        border-radius: 50%;

        display: flex;
        height: 1.5rem;
        -webkit-box-pack: center;
        justify-content: center;

        left: 0px;
        
        opacity: 0;
        transition: opacity 200ms ease 0s;
        
        position: absolute;
        top: 0px;
        width: 1.5rem;
    }
  }
  .radio__hidden:checked + .radio__label {
    &:before{
        background-color: rgb(15, 88, 214);
    }
    &:after {
      opacity: 1;
      visibility: visible;
      transform: scale(0.4);
    }

  }