.input-shell {
  width: 100%;
}



.input-shell__label {
  @include body-light;
  display: block;
  margin-bottom: measure(quarter);
  color: color(text-inverted);
}

.input-shell__label--dark {
  color: color(input-label-text-dark);
}

.input-shell__label--required:after {
  @include body-small;
  content: "*";
  display: inline-block;
  margin-left: measure(eighth);
  .invalid &{
    content: "";
    width: 12px;
    height: 12px;
    background-image: icon(invalid);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
}

.input-shell__container {
  @include body-light;
  position: relative;
}

.input-shell__container--double-inputs {
  display: flex;
  align-items: center;
}

.input-shell__container--image-container {
  display: flex;
}

.input-shell__container--image-container input[type="file"] {
  display: none;
}

.input-shell__container > *:not(.input-shell__svg) {
  width: 100%;
}

.input-shell__container--double-inputs > *:not(.input-shell__svg) {
  width: auto;

  &:first-child {
    flex-grow: 1;
    margin-right: measure(half);
  }

  &:last-child {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 10.5rem;
    max-width: 10.5rem;
  }
}

.input-shell__svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.input-shell__svg--left {
  left: measure(half);
}

.input-shell__svg--right {
  right: measure(half);
}

.input-shell__svg--calendar {
  top: 0.75rem;
  transform: none;
}

.input-shell__message {
  @include caption;
  padding-top: measure(quarter);
}

.input-shell__message-error {
  color: color(red-1);
}

.input-shell__message-hint {
  font-size: 0.75rem;
  color: color(input-hint-text);
}

.input-shell-images {
  list-style-type: none;
  padding: 0;
}

.input-shell-images__item {
  margin-bottom: measure(full);

  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    cursor: pointer;
    background-color: color(gray-4);
  }
}

.input-shell-images > button {
  width: 100%;

  @include media(small) {
    width: 9.375rem;
  }
}

.input-shell-images__image {
  display: block;
  margin-bottom: measure(quarter);
  max-width: 75%;
}

.select__element{
  .invalid &{
    border: 1px solid color(input-border-error);
  }
}

.field-error{
  position: relative;
  &__overlay{
    position: absolute;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    left: -10px;
    top: -10px;
    background-color: red;
    opacity: 0.17;
    svg{
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
.field-saving{
  position: relative;
  &__overlay{
    position: absolute;
    width: calc(100%);
    height: calc(100%);
    left: 0;
    top: 0;
    svg{
      width: 16px;
      height: 12px;
      position: absolute;
      top: 0;
      right: 0;
      animation-name: spin;
      animation-duration: 2000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear; 
    }
  }
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}