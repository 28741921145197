.update-modal-container{
    width: 400px;
    @include media(to-large) {
        width: 50vw;
    }    
    .update-modal__body{
        display: flex;
        flex-direction: column;
        h2{
            text-align: center;
        }
    }
} 