@mixin container($padding: true, $wider: false) {
  width: 100%;
  margin: 0 auto;
  
  @if $padding {
    padding-right: measure(half);
    padding-left: measure(half);
  }
  
  @include media(small) {
    max-width: calc(#{measure(container)});
    
    @if $padding {
      $padding-small: measure(full) * 2;
      
      @if $wider {
        max-width: calc(#{measure(container-wider)} - #{$padding-small});
      } @else {
        max-width: calc(#{measure(container)} - #{$padding-small});
      }
      padding-right: measure(full);
      padding-left: measure(full);
    }
  }
}

@mixin fake-icon($width: 1rem, $height: 1rem, $pseudo: null) {
  @if ($pseudo != null) {
    &:#{$pseudo} {
      content: '';
      display: inline-block;
      width: $width;
      height: $height;
      background-color: color(fake-icon);
    }
  } @else {
    display: inline-block;
    width: $width;
    height: $height;
    background-color: color(fake-icon);
  }
}


@mixin reset-link() {
  color: color(link-color);
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

@mixin reset-list {
  list-style-type: none;
  margin: 0;
}

@mixin reset-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  // margin: 0;
  padding: 0;
  
  &:hover {
    cursor: pointer;
  }
}
