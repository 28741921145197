.element--is-loading {
  pointer-events: none;
}

.element--is-loading-before:before,
.element--is-loading-after:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 300 300' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3Cpath d='M 150,0 a 150,150 0 0,1 106.066,256.066 l -35.355,-35.355 a -100,-100 0 0,0 -70.711,-170.711 z' fill='%23006DB6'%3E%3CanimateTransform attributeName='transform' attributeType='XML' type='rotate' from='0 150 150' to='360 150 150' begin='0s' dur='.6s' fill='freeze' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E");
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-color: rgba(256, 256, 256, 0.8);
  background-position: center center;
  z-index: 2;
}
