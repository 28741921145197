.info-message-container {
  margin-left: measure(full);
  margin-right: measure(full);
  margin-top: measure(half);
}

.info-message-text {
  color: color(blue-3);
  vertical-align: text-top;
  font-size: font(body-small);
}
