.table-cell-status {
  display: flex;
  align-items: center;
  white-space: nowrap;

  &::before {
    content: '';
    flex-shrink: 0;
    display: inline-block;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    margin-right: measure(quarter);
  }
}

.table-cell-status--no-status::before {
  display: none;
}

.table-cell-status--green::before {
  background-color: color(indicator-dot-green);
}

.table-cell-status--yellow::before {
  background-color: color(indicator-dot-yellow);
}

.table-cell-status--red::before {
  background-color: color(indicator-dot-red);
}

.table-cell-status--black::before {
  background-color: color(indicator-dot-black);
}
