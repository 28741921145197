.history-modal{
    width: 60vw;
}

.casehistory{
    text-align:left;
    &__majorevent{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: measure(half);
        padding: measure(half);
        border: solid 3px color(border-gray);
        .info{
            width: 80%;
        }
        .action{
            cursor: pointer;
        }
        .fieldChanges{
            flex-basis: 100%;
            display: flex;
            flex-direction: column;
            font-size: 12px;
            &__row{
                margin-bottom: measure(quarter);
                padding: measure(quarter);
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                .medium-column{
                    width: calc(50%/2 - 5%);
                }
                .short-column{
                    width: calc(45%/3 - 5%);
                }
                .icon-column{
                    cursor: pointer;
                    width: 5%;
                }
                &:nth-child(2n){
                    background-color: color(gray-6);
                }
                .fieldChanges{
                    font-size: 10px;
                    border: solid 2px color(border-gray);
                    margin-top: measure(half);
                    .icon-column{
                        display:none;
                    }
                }
            }
        }
    }
}