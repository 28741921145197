.radio-button-group {
  display: flex;
}

.radio-button-group > * {
  margin-right: measure(full);
}

.radio-button {
  display: flex;
  align-items: center;
}

.radio-button__hidden {
  display: none;
}

.radio-button__label {
  @include body-light;
  position: relative;
  display: flex;
  align-items: center;
  height: 1.5rem;
  padding-left: measure(double);
  color: color(input-label-text-dark);
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    background-color: color(radio-button-background-not-selected);
    border: 1px solid color(input-border);
    border-radius: 50%;
    transform: translateY(-50%);
  }

  &:after {
    content: '' ;
    position: absolute;
    left: .42rem;
    top: 50%;
    width: 0.65rem;
    height: 0.65rem;
    background-color: color(radio-button-background-not-selector);
    border-radius: 50%;
    transform: translateY(-50%) scale(.4);
    opacity: 0;
    visibility: hidden;
    transition: all 220ms;
  }
}

.radio-button__label-span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.radio-button__hidden:checked + .radio-button__label {
  &::before {
    background-color: color(radio-button-background-selected);
  }

  &:after {
    background-color: color(radio-button-background-selector);
    opacity: 1;
    visibility: visible;
    transform: translateY(-50%) scale(1);
  }
}
