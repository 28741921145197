.traffic-light{
    border: solid 1px #86989C;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    width: 11rem;
    padding:measure(quarter);
    padding-bottom:measure(eighth);
    background-color: color(background-white);
    div{
        width: auto !important;
    }
    .light{
        cursor: pointer;
    }
}

.live-preview-images__severity{
    .traffic-light{
        width: 6rem;
        padding:measure(eighth);
        padding-bottom:0;
    }
}