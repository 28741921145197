.page-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media(small) {
    flex-direction: row;
  }
}

.page-header.page-layout__heading-title {
  margin-bottom: measure(full);
  align-items: flex-start;

  @include media(small) {
    margin-bottom: measure(double);
  }
}

.page-header__element {
  @include h1;
  order: 2;
  margin: 0;
  text-transform: uppercase;

  @include media(small) {
    order: 1;
  }
}

.page-header__element + * {
  order: 1;
  width: 100%;
  margin-bottom: measure(full);

  @include media(small) {
    order: 2;
    width: initial;
    margin-bottom: 0;
    margin-left: measure(double);
  }
}
