.case-search-box__container{
    margin-bottom:measure(full);
}

.table__header-inset--button:hover{
    cursor: auto;
}

.table__body-cell {
    cursor: auto;
}

.table__body-cell-pdf{
    cursor: pointer;
}