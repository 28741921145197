.login {
  @include container;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding-top: measure(quad);

  @include media(small) {
    padding-top: calc(#{measure(quad)} * 2);
  }
}

.login__bart-logo {
  margin-bottom: measure(quad);
  color: color(link-color)
}

.login--set-password .login__bart-logo {
  margin-bottom: measure(quarter);
}

.login__header {
  @include h2;
  margin-top: 0;
}

.login__form {
  max-width: 18rem;
  width: 100%;
}

.login--set-password .login__text {
  margin-bottom: measure(quad);
}

.login__input-containers {
  margin-bottom: measure(half);
}

.login__input-containers--last {
  margin-bottom: measure(full);
}

.login__button {
  width: 100%;
  margin-bottom: measure(full);
}

.login__errors{
  color: color(text-error);
}

.login__request-password-row {
  margin-bottom: measure(quarter);
}

.login__request-password-link {
  @include reset-link;
}

.login__button-full-width {
  width: 100%;
}
