.input__element {
  @include body-light;
  display: block;
  width: 100%;
  height: 2.75rem;
  padding: 0 measure(half);
  border: 1px solid color(input-border);
  background-color: color(input-background);
  .invalid &{
    border: 1px solid color(input-border-error);
  }
  &:disabled{
    border: 1px solid color(input-border-disabled);
    background-color: color(input-background-disabled);
  }
}

.input__element--use-icon {
  padding-right: measure(quad);
}

.input__element--use-icon-left {
  padding-left: measure(quad);
}

.input__textarea {
  display: block;
  width: 100%;
  max-width: 100%;
  min-height: 5rem;
  padding: measure(quarter) measure(half);
  border: 1px solid color(input-border);
  background-color: color(input-background);
}
