.secondary-header {
  position: sticky;
  top: 0;
  background-color: color(modal-header-background);
  z-index: 14;
}

.secondary-header__container {
  @include container;
  display: flex;
  align-items: center;
  height: 3.75rem;
}

.secondary-header__loading-area {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 measure(double);
  background-color: color(background-white);
  color: color(text-green);
  text-transform: uppercase;
}

.secondary-header__loading-svg {
  margin-right: measure(quarter);
  animation: rotate 1.35s linear infinite;
}

.secondary-header__close-action {
  @include reset-link;
  display: flex;
  align-items: center;
  margin-left: auto;
  text-transform: uppercase;
}

.secondary-header__close-svg {
  width: 1.875rem;
  height: 1.875rem;
  margin-left: measure(quarter);
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
