$half: measure(quarter);
$negativehalf: measure(negativehalf);
$arrowSize: 8px;
.figure-canvas-buttons{
    display:flex;
    flex-direction: row;
    justify-content: center;
    .figure-canvas-buttonGroup{
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 0 measure(quarter);
        button{
            margin-bottom: measure(half);
            margin-right:measure(quarter);
            margin-left:0;
        }
        .picker-container{
           position: relative;
           .picker{
               display:flex;
               flex-direction: row;
               justify-content: flex-start;
               position: absolute;
               background-color: color(gray-4);
               padding: measure(quarter);
               
               button{
                margin-bottom: 0;
                &:last-of-type{
                    margin-right: 0;   
                }
               }

               &::after {
                content: " ";
                position: absolute;
                top: calc(-#{$arrowSize}*2);
                left: 50%;
                margin-left: -#{$arrowSize};
                border-width: #{$arrowSize};
                border-style: solid;
                border-color: transparent transparent color(gray-4) transparent;
               }
               &.circlepicker{
                left:calc(-300% - #{$half}); //-300% depends on number of items using: l = -50%(n - 1)
                margin-top: calc(#{$negativehalf} + #{$arrowSize}*3);
               }
               &.thicknesspicker{
                left:calc(-100% - #{$half}); //-200% depends on number of items using: l = -50%(n - 1)
                margin-top: calc(#{$negativehalf}*2 + #{$arrowSize}*3 + 3px);
               }
               &.slider{
                left: -73px;
                    input{
                        width:170px;
                    }
               }
           }

           .button-circle{
                width:48px;
                height: 48px;
                margin-bottom: 0;
                &:disabled {
                    svg{
                        g{
                            stroke: color(button-toggler-disabled);
                            path{
                                fill: color(button-toggler-disabled);
                            }
                        }
                    }
                }
            }
            .button--background-green-circle{
                background-color: color(button-default-background);
                border-radius: 2.8125rem;
                &:hover {
                    background-color: color(button-default-hover);
                  }
                
                  &:disabled {
                    background-color: color(button-toggler-disabled);
                    pointer-events: none;
                  }
            }
            .button--background-orange-circle{
                background-color: color(button-favorite-background);
                border-radius: 2.8125rem;
                &:hover {
                    background-color: color(button-default-hover);
                  }
                
                  &:disabled {
                    background-color: color(button-toggler-disabled);
                    pointer-events: none;
                  }
            }
        }
    }
    
    
}

.figure-canvas--image{
    padding: measure(full);
    display: flex;
    justify-content: center;
}

.react-modal__body{
    .figure-canvas--image{
        margin-bottom:0;
    }
}