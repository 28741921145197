.filter__container {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.filter__options {
  display: flex;
  gap: 2rem;

  @include media(to-medium) {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
}

.dropdowns__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.filter__select_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form_control {
  display: flex;
  flex-direction: column;
  gap: .33rem;

  &--dates {
    @extend .form_control;
    margin-top: 1rem;
  }
}

.date__filters_wrapper {
  display: flex;
  gap: 1rem;
}

.button__chips_container {
  display: grid;
  grid-auto-rows: 4rem;
  grid-template-columns: repeat(4, 1fr);
  font-weight: bold;
  gap: 1rem;

  @include media(to-medium) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media(to-small) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.filter__actions {
  display: block;
  height: fit-content;
  margin-top: 1.6rem;
}

.filter__chip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: .5rem;
  padding: .5rem 1rem;
  background-color: color(green-1);
  border: 1px solid transparent;
  border-radius: .5rem;
  cursor: pointer;
  position: relative;
  transition: border .1s;
  color: color(white);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &::after {
    content: '✕';
    font-size: 1.2em;
    font-weight: bold;
    color: color(black-2);
    position: absolute;
    top: 50%;
    right: .5rem;
    transform: translateY(-50%);
    background-color: color(gray-6);
    border-radius: 50%;
    padding: .3rem .5rem;
    opacity: 1;
  }
}


.filter__text,
.filter__text-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 11rem;
  font-size: 1em;
}

.filter__text-title {
  font-weight: 600;
  color: color(white);
}