.message-bar {
  padding: measure(quarter) 0;
  z-index: 3;
}

.message-bar--is-offline {
  position: sticky;
  top: 0;
}

.message-bar--warning {
  background-color: color(message-bar-background-warning);
  color: color(message-bar-background-warning-text);
  z-index:14;
}
.message-bar--success {
  background-color: color(message-bar-background-success);
  color: color(message-bar-background-success-text);
  z-index:14;
}
.message-bar--error {
  background-color: color(message-bar-background-error);
  color: color(message-bar-background-error-text);
  z-index:14;
}


.message-bar__container {
  @include container;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-bar__svg {
  margin-right: measure(quarter);
}
