.icon-message{
    margin-left:measure(half);
    
    .icon-message__text{
        text-align: left;
        margin-left: measure(double);
    }
    .icon-message__icon{
        display:flex;
        flex-direction: row;
        margin-top: measure(half);
        svg{
            width: measure(double);
            margin: auto 0;
        }
        div{
            text-align: left;
            padding-left: measure(quarter);
        }
    }
    
}