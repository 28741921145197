.ReactModalPortal {
  position: relative;
  z-index: 20;
}

.react-modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
}

.react-modal {
  position: absolute;
  top: 3rem;
  left: 50%;
  width: 100%;
  height: auto;
  max-height: calc(100vh - 3rem);
  background-color: color(background-white);
  transform: translateX(-50%);
  outline: none;
  overflow: auto;

  @include media(to-large) {
    width: 77%;
  }

  @include media(large) {
    width: 60%;
  }

  @include media(to-small) {
    width: 85%;
  }
}

.react-modal-convert-table {
  @extend .react-modal;
  width: 70%;

  @media(x-small) {
    width: 100%;
    max-height: 80vh;
    overflow: auto;
    top: 3rem;
  }

  @include media(to-medium) {
    width: 90%;
    max-height: 80vh;
    overflow: auto;
    top: 3rem;
  }

  @include media(to-large) {
    width: 90%;
    max-height: 80vh;
    overflow: auto;
    top: 3rem;
  }
}

.react-modal-duplicate-case {
  @extend .react-modal;
  width: 100%;
  top: 3rem;
  max-height: 80vh;
  overflow: auto;
}

.modal__license-agreement {
  @extend .react-modal;
  width: fit-content;
  top: 3rem;
  max-height: 100vh;
  overflow: auto;
}

.modal__license-agreement__rejected {
  @extend .react-modal;
  width: 40%;
  height: 20rem;
  top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media(to-large) {
    width: 90%;
    max-height: 80vh;
    overflow: auto;
    top: 3rem;
  }
}

.react-modal--mobile {
  top: 0 !important;
}

.react-modal__header {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 4rem;
  padding: measure(quarter) measure(half);
  background-color: color(modal-header-background);
  z-index: 1;
}

.react-modal__close-button {
  @include reset-button;
  width: 1.875rem;
  height: 1.875rem;

  >svg {
    width: 100%;
    height: 100%;
  }
}

.react-modal__body {
  padding: measure(half);
  color: color(text);
  text-align: left;

  @include media(small) {
    padding: measure(quad);
  }
}

.react-modal__body>*:not(:last-child) {
  margin-bottom: measure(half);

  @include media(small) {
    margin-bottom: measure(double);
  }
}

.react-modal__text {
  display: block;
  text-align: center;
  gap: 1rem;
}

.react-modal__actions-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media(small) {
    flex-direction: row;
  }
}

.react-modal__actions-container:not(.react-modal__actions-container--canvas-controls)>button {
  @include media(to-small) {
    width: 100%;

    &:first-child {
      order: 2;
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: measure(half);
    }
  }
}

.react-modal__actions-container--canvas-controls {
  flex-direction: row;
  justify-content: flex-end;
}

.react-modal__actions-container--canvas-controls>button:not(.react-modal__clear-action) {
  margin-right: measure(quarter);

  &:first-child {
    margin-left: auto;
  }

  &:last-child {
    margin-right: 0;
  }
}

.react-modal__action-button {
  width: 7.5rem;

  &:first-child {
    margin-right: measure(quarter);
  }
}

.react-modal__action-button--wide {
  width: auto;
}

.react-modal__clear-action {
  margin-right: auto;
}

.react-modal__action-full-width {
  width: 100%;
}

.convertion__selected__bearings {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.convertion__table__checkbox {
  display: flex;
  margin: 0 auto;
  transform: scale(1.3);
}

.convertion__successful__dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.convertion__title {
  text-align: left;
  margin-top: 0;
}

.convertion__description {
  text-align: left;
  font-weight: bold;
}

.modal__license-agreement {
  width: 80%;
  height: 75%;
}

.modal-license_agreement_body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.react-modal__content--rejected {
  width: 40%;
  height: 10rem;
}

.license-text_modal {
  height: 25rem;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  color: #343434;
  background-color: #f9f9f9;
}

.license-text_page {
  overflow-y: auto;
  padding: 1rem 2.5rem;
  color: #343434;

  @include media(large) {
    padding: 1rem 8.5rem;
  }
}

.la_chapter {
  font-weight: bolder;
  margin: 0;
}

.la_attachment {
  text-decoration: underline;
  color: rgb(11, 70, 210);
}

.license-text p {
  font-size: .95em;
}

.license-heading {
  font-weight: bold;
  text-align: center;
  font-size: 1.4em;
}

.bolded_paragraph {
  font-weight: bold;
  line-height: 1.4;
}

.license-subheading {
  text-align: center;
  font-size: 1.2em;
}