.select-search {
  background-color: deepskyblue;
}

.select-search .select-search__control {
  height: 2.75rem;
  padding: 0 measure(half);
  border: 1px solid red;
  // background-color: color(input-background);
  background-color: deepskyblue !important;
  border-radius: 0 !important;
}
