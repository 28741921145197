.footer {
  background-color: color(footer-background);
  margin-top: auto;
}

.footer__container {
  width: 100%;
  margin: 0 auto;
  max-width: calc(#{measure(container)});
  display: flex;
  align-items: center;
  padding: measure(full);
  color: color(text-inverted);
}

.footer__logo {
  margin-right: measure(half);

  @include media(medium) {
    margin-right: measure(full);
  }
}

.footer__copyright {
  @include body-small;
  padding: 0 measure(full);
  text-transform: capitalize;
  color: color(aqua-1);
  display: flex;
}

.footer__link {
  @include body-small;
  display: none;
  padding: 0 measure(full);
  text-decoration: none;
  color: color(aqua-1);

  // &:hover {
  //   background-color: color(nav-active-background);
  // }

  @include media(small) {
    display: inline-block;
  }
}
