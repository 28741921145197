.content-container__header {
  @include h2;
  margin: 0 0 measure(half) 0;
  color: color(table-descriptive-text);
  line-height: initial;
  overflow: hidden;
}

.content-container__content {
  overflow-x: auto;
}

.content-container__content--frame {
  background-color: color(content-background);
  
  @include media(medium) {
    padding: measure(double);
  }
}

.content-container__content--has-link > *:first-child {
  margin-bottom: measure(full);
}

.content-container__content--has-toggler > *:first-child {
  margin-bottom: measure(full);
}

.content-container__toggle-container {
  display: flex;
  justify-content: center;
}

.content-container__toggler {
  @include reset-button;
  @include reset-link;
  display: flex;
  align-items: center;
  color: color(link-color);
  overflow: hidden;
}

.content-container__svg-arrow {
  margin-left: measure(quarter);
  transition: transform 140ms;
}

.content-container__link {
  display: flex;
  align-items: center;
  color: color(link-color);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:after {
    content: '';
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 15l7-7-7-7' fill-rule='nonzero' stroke='%230F58D6' stroke-width='2' fill='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: measure(half);
    width: 0.5625rem;
    height: 0.875rem;
  }
}
