.case-search-box__container {
  @include container;
  max-width: 62.5rem !important;
  position: relative;
}

.case-search-box__search-with-results--wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
  
  @include media(medium) {
    display: flex;
    background: color(white);
    border: 1px solid color(border-gray);
    padding: measure(half);
  }
}

.case-search-box__search-with-results {
  margin-bottom: measure(half);
  
  @include media(medium) {
    flex-grow: 1;
    margin-right: measure(quarter);
    margin-bottom: 0;
  }
}

.case-search-box__result-list-container {
  position: relative;
  width: 100%;
}

.case-search-box__result-list {
  position: absolute;
  z-index: 1;
  width: 100%;
  background: color(white); 
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid color(border-gray);
}

.case-search-box__result-item {
  padding: measure(half) measure(half);
  border-bottom: 1px solid color(border-gray);
  
  &:last-child {
    border-bottom: none;
  }

  &:hover, &:focus {
    cursor: pointer;
    background: color(tabs-action-active);
  }

  &.unclickable {
    pointer-events: none;
    background: none;
    cursor: initial;
  }
}

.case-search-box__result-item--result {
  margin-right: measure(quarter);
}

.case-search-box__result-item--group-name {
  text-transform: capitalize;
  @include skf_sans_light;
  color: #87999D;
}

.case-search-box__search_input {
  input {
    border-color: transparent;
    border: 1px solid color(border-gray);
    
    @include media(medium) {
      border: none;
    }
  }
}

.case-search-box__clear-svg {
  pointer-events: auto;

  &:hover {
    cursor: pointer;
  }
}

.case-search-box__submit-button {
  width: 100%;

  @include media(medium) {
    width: auto;
    min-width: 200px;
  }
}