.button-bar__container{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    padding-top: 15px;
    margin-top: -15px;
    margin-bottom: measure(full);
    background-color: color(gray-6);
    z-index:13;
    
    @include media(medium) {
        padding-top: 15px;
        margin-top: -15px;   
    }
    &.noconnection{
        padding-top: 46px;
        background-color: transparent;
    }
    .button-bar{
        display:flex;
        background-color: color(green-1);
        padding: 7px;
        &__group{
            display:flex;
            position: relative;
            border-right:solid 1px white;
            padding: 5px;
            padding-right:0px;
            padding-left:10px;
            &:last-of-type{
                border-right:0;
            }
            .button{
                margin-right: 10px;
            }
            .button--icon-only{
                width: 24px;
                height: 24px;
            }
            .button--icon-saving{
                background-color: color(green-1);
                svg{
                    display:none;
                }
                &:after{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 300 300' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3Cpath d='M 150,0 a 150,150 0 0,1 106.066,256.066 l -35.355,-35.355 a -100,-100 0 0,0 -70.711,-170.711 z' fill='%23006DB6'%3E%3CanimateTransform attributeName='transform' attributeType='XML' type='rotate' from='0 150 150' to='360 150 150' begin='0s' dur='0.6s' fill='freeze' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E");
                    background-size: calc(2rem*0.7) calc(2rem*0.7);
                    background-repeat: no-repeat;
                    background-color: color(green-1);
                    background-position: center center;
                    z-index: 2;
                }
            }
            .button--icon-disabled{
                cursor:not-allowed;
                //background-color: color(gray-1);
                svg{
                    path {
                        fill: color(gray-3);
                    }
                    polygon {
                        fill: color(gray-3);
                    }
                    g{
                        fill: color(gray-3);
                        path {
                            fill: color(gray-3);
                        }
                        g{
                            fill: color(gray-3);
                        }
                    }
                }
            }
        }
        &__right{
            margin-left: auto;
        }
    }
}
