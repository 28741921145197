.navigation {
  z-index: 1;

  @include media(to-medium) {
    background-color: color(nav-active-background);
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: auto;
    z-index: 2;
  }

  @include media(medium) {
    flex-grow: 1;
  }
}

.navigation--is-open {
  transform: translateX(0);
}

.navigation__container {
  display: flex;
  flex-direction: column;
  overflow: auto;

  @include media(medium) {
    flex-direction: row;
    align-items: center;
    height: 4.375rem;
    overflow: initial;
  }
}

.navigation__header {
  @include body;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  border-bottom: 1px solid color(nav-border);
  color: color(text-inverted);

  @include media(medium) {
    display: none;
  }
}

.navigation__header-close-button {
  @include reset-button;
  position: absolute;
  top: 50%;
  right: measure(half);
  width: 1.875rem;
  height: 1.875rem;
  overflow: hidden;
  transform: translateY(-50%);

  & > svg {
    width: 100%;
    height: 100%;
  }
}

.navigation__action {
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.125rem;
  margin-bottom: measure(eighth);
  padding: 0 measure(full);
  background-color: transparent;
  border: none;
  color: color(text-inverted);
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  @include media(to-navigation) {
    border-bottom: 1px solid color(nav-border);
  }

  &:hover {
    cursor: pointer;
    background-color: color(nav-active-background);
  }

  @include media(medium) {
    width: auto;
    height: 4.375rem;
    margin-bottom: 0;
    padding: 0 measure(half);
  }
}

.navigation__action--active {
  background-color: color(nav-active-background);
  margin-bottom: 0;
}

.navigation__action--search {
  margin-left: auto;
}

.navigation__action-svg {
  margin-right: measure(quarter);
}

.navigation__action-svg-arrow {
  margin-left: auto;
  transition: transform 140ms;

  @include media(medium) {
    margin-left: measure(quarter);
  }
}

.navigation__action--active .navigation__action-svg-arrow {
  transform: scaleY(-1);
}

.navigation__sub-navigation-container {
  position: relative;
}

.navigation__sub-navigation-items {
  display: flex;
  flex-direction: column;

  @include media(medium) {
    position: absolute;
    top: 100%;
    left: 0;
    padding-top: measure(double);
    background-color: color(nav-active-background);
  }
}

.navigation__sub-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: color(text-inverted);
  background-color: transparent;
  border-bottom: 1px solid color(nav-border);
  height: 3rem;
  padding: 0 measure(full) 0 measure(quad);
  white-space: nowrap;
  text-decoration: none;

  &:hover {
    background-color: color(nav-background);
  }

  @include media(medium) {
    padding: 0 measure(double);

    &:first-child {
      border-top: 1px solid color(nav-border);
    }
  }
}