.to-top {
  @include reset-button;
  position: fixed;
  bottom: measure(full);
  right: measure(full);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  background-color: color(gray-1);
  color: color(text-inverted);
  border-radius: 50%;
}

.to-top__action-svg-arrow {
  transform: scale(-1);
}
