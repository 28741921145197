.rich-text{
    .sun-editor{
        .se-btn{
            color:white;
            background-color: color(green-1);
            &:enabled.active{
                background-color: color(green-1);
                color:color( gray-1);
            }
            &:disabled{
                color:color( gray-1);
            }
        }

        .sun-editor-editable{
            strong{
                font-weight: bold;
            }
        }
        // .se-toolbar{
        //     z-index:0
        // }
        // .se-wrapper{
        //     z-index:0
        // }
    }

    .invalid & {
        border: 1px solid color(input-border-error);
    }
    
}