.checkbox {
  display: flex;
  align-items: center;
}

.checkbox__disabled {
  opacity: 0.5;
}

.checkbox__hidden {
  display: none;
}

.checkbox__label {
  @include body-light;
  position: relative;
  display: flex;
  align-items: center;
  height: 1.5rem;
  padding-left: calc(1.5rem + #{measure(half)});
  color: color(text-inverted);

  &:hover {
    cursor: pointer;
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    background-color: color(input-background);
    border: 1px solid color(input-border);
    transform: translateY(-50%);
    transition: background-image 200ms;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 1.5rem;
    height: 1.5rem;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='14' viewBox='0 0 18 14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.083 6.167L.917 8.242l5.07 5.292 11.52-10.422L15.493.888 6.136 9.353z' fill='%230F58D6' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center center;
    transform: translateY(-50%) scale(0.4);
    opacity: 0;
    visibility: hidden;
    transition: all 120ms;
  }
}

.checkbox__label-span {

}

.checkbox__hidden:checked + .checkbox__label {
  &:after {
    opacity: 1;
    visibility: visible;
    transform: translateY(-50%) scale(1);
  }
}

.checkbox__label--dark {
  color: color(input-label-text-dark);
}
