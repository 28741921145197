.environment-bar {
    padding: measure(quarter) 0;
    z-index: 3;
    background-color: color(orange-1);
}

.environment-bar__container {
    @include container;
    display: flex;
    align-items: center;
    justify-content: center;
}