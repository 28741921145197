.fileimage-header{
    display:flex;
    justify-content: space-between;
}

.image-selector{
    max-height: 270px;
    overflow: scroll;
    display:flex;
    flex-wrap: wrap;

    overflow-x: hidden;
    overflow-y: auto;

    .image-selector__item{
        position:relative;
        background-size: cover;
        background-repeat: no-repeat;
        flex: 0 0 100px;
        margin: 3px;
        height: 100px;
        border: solid 3px color(border-gray);
        cursor: pointer;

        display:flex;
        align-items: center;
        justify-content: center;

        .image-selector__button{
            
        }
     
        .image-selector__editbutton{
            display:none;
        }


        &:hover{
            .image-selector__editbutton{
                display:block;
            }
        }
    }

    .image-selector__item-add{
        background-color: color( gray-2);
    }

    .image-selector__overlay{
        position: absolute;
        bottom: 0;
        background: rgba(220, 224, 225, 0.4);
        width: 100%;
        transition: .5s ease;
        opacity: 1;
        height: 100%;

        &:hover{
            opacity: 0;
        }
    }

    .selected{
        .image-selector__overlay{
            opacity: 0;
        }
    }

    input[type=file] {
        display: none;
    }

    
}

.imageLayout-select__control{
    .imageLayout-select__value-container{
        div{
            margin:0;
            padding:0;
        }
        .imageLayout-select__input{
            input{
                caret-color: transparent;
            }
        }
    }   
}

.imageLayout-select__option--is-selected {
    svg{
        .imageLayout-icon-bg{
            fill: #FFF;
        }
        .imageLayout-icon-fg{
            
            fill: #88C008;
        }
    }
}
.imageLayout-select__option--is-focused{
    svg{
        .imageLayout-icon-bg{
            fill: #FFF;
        }
        .imageLayout-icon-fg{   
            fill: #88C008;
        }
    }
}