.offline-modal{
    width: 1000px;
    @include media(to-large) {
        width: 90vw;
    }    
    .react-modal__header{
        justify-content: space-between;
    }
    .react-modal__body{
        padding-top: measure(half);
    }
    .react-modal__text{
        text-align: left;
        max-height: 50vh;
        overflow-y: auto;
    }
    .react-modal__action-button{
        width:auto;
    }
    .showpointer{
        cursor: pointer;
        .table__body-cell{
            cursor: pointer;
        }
    }
}