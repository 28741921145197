#app {
  @include media(medium) {
    min-width: fit-content;
  }
}

.header {
  background-color: color(header-background);
  position: sticky;
  top: 0;
  z-index: 3;
}

.header--is-offline {
  top: 2.875rem;
}

.header__container {
  @include container;
  min-width: min-content;

  display: flex;
  align-items: center;
  height: 3.75rem;

  @include media(small) {
    height: 4.375rem;
  }
}

.header__action {
  display: flex;
  align-items: center;
  padding: 0 measure(half);

  @include media(small) {
    padding: 0 measure(full);
  }
}

.header__action--logos-container {
  height: 100%;
  margin-right: measure(quad);
  padding: 0;
  color: color(text-inverted);
}

.header__action-skf-logo {
  margin-right: measure(half);

  @include media(small) {
    margin-right: measure(full);
  }
}

.header__action--bart-logo {
  width: 5.125rem;

  @include media(small) {
    width: 5.375rem;
  }
}

.header__logo {
  height: 1.25rem;
}

.navigation-toggler.header__navigation-burger {
  display: inline-block;
  margin-left: auto;

  @include media(medium) {
    display: none;
  }
}
