.table-component{
    width: 1200px;
    @include media(to-large) {
        width: 90vw;
    }
    &__header{
        justify-content:flex-start
    }
    &__body{
        max-height: calc(100vh - 16rem);
        overflow: auto;
        table{
            width:100%;
            border-spacing: 0;
            tr{
                background-color: color(table-body-background-even);
                &:nth-child(even) {
                    background-color: color(table-body-background-odd);
                }
            }
            th{
                @include body-small;
                padding: measure(quarter) measure(half);
                background-color: color(live-preview-dark-background);
                color: color(text-inverted);
                text-transform: uppercase;
            }
            td{
                padding-left: measure(quarter);
                padding-bottom: measure(half);
                vertical-align:top;
                .remove{
                    margin-top: measure(full);
                    margin-right: measure(quarter);
                    cursor: pointer;
                }
            }
        }
    }
    &__footer{
        display:flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding: measure(full) measure(quad);
    }
}