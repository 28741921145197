.dashboard-search {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 14rem;
  align-items: center;
  justify-content: center;
  
  @include media(medium) {
    height: 20rem;
  }
}
