$normalSize: 2.8125rem;
$dateRangeSize: 2.1rem;

// overrides 
.react-datepicker-popper {
  display: flex;
  justify-content: center;
  position: relative !important;
  margin-top: 0 !important;
  padding: measure(double) 0;
  transform: none !important;
  border: 1px solid color(input-border);
  border-top: none;

}

.date-range-picker .react-datepicker-popper {
  padding-top: measure(half);
  padding-bottom: 0;
  border: none !important;
}

.react-datepicker__triangle {
  display: none;
}

.date-picker {
  @include body;
  position: relative;
}

.date-picker .react-datepicker__navigation {
  @include reset-button;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(2.375rem + #{measure(eighth)});
  height: 2.375rem;
  background-color: color(calendar-item-background);

  @include media(small) {
    width: calc(#{$normalSize} + #{measure(eighth)});
    height: $normalSize;
  }
}

.date-range-picker .date-picker .react-datepicker__navigation {
  @include media(small) {
    width: calc(#{$dateRangeSize} + #{measure(eighth)});
    height: $dateRangeSize;
  }
}

.date-picker .react-datepicker__navigation--previous {
  left: 0;
  border-left: measure(eighth) solid color(calendar-row-background);
}

.date-picker .react-datepicker__navigation--next {
  right: 0;
  border-right: measure(eighth) solid color(calendar-row-background);
}

.react-datepicker__aria-live {
  display: none;
}

.date-picker .react-datepicker__current-month {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - ((2.375rem * 2) + (#{measure(eighth)} * 2)));
  height: 2.375rem;
  margin: 0 auto measure(eighth) auto;
  background-color: color(calendar-item-background);
  border: solid color(calendar-row-background);
  border-width: 0 measure(eighth);
  font-size: 1rem;
  font-weight: 500;

  @include media(small) {
    width: calc(100% - ((#{$normalSize} * 2) + (#{measure(eighth)} * 2)));
    height: $normalSize;
  }
}

.date-range-picker .date-picker .react-datepicker__current-month {
  @include media(small) {
    width: calc(100% - ((#{$dateRangeSize} * 2) + (#{measure(eighth)} * 2)));
    height: $dateRangeSize;
  }
}

.date-picker .react-datepicker__day-names {
  display: flex;
  padding: 0 measure(eighth);
  background-color: color(calendar-row-background);
  margin-bottom: measure(eighth);
}

.date-picker .react-datepicker__day-name {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.375rem;
  height: 2.375rem;
  background-color: color(calendar-item-background);
  
  &:not(:last-child) {
    margin-right: measure(eighth);
  }
  
  @include media(small) {
    width: $normalSize;
    height: $normalSize;
  }
}

.date-range-picker .date-picker .react-datepicker__day-name {
  @include media(small) {
    width: $dateRangeSize;
    height: $dateRangeSize;
  }
}

.date-picker .react-datepicker__week {
  display: flex;
  padding: 0 measure(eighth);
  background-color: color(calendar-row-background);

  &:not(:last-child) {
    margin-bottom: measure(eighth);
  }
}

.date-picker .react-datepicker__day {
  @include reset-link;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.375rem;
  height: 2.375rem;
  background-color: color(calendar-item-background);
  
  &:not(:last-child) {
    margin-right: measure(eighth);
  }
  
  @include media(small) {
    width: $normalSize;
    height: $normalSize;
  }
}

.date-range-picker .date-picker .react-datepicker__day {
  @include media(small) {
    width: $dateRangeSize;
    height: $dateRangeSize;
  }
}

.date-picker .react-datepicker__day--selected {
  background-color: color(calendar-selected-day-background);
  color: color(text-inverted);
}

.date-picker .react-datepicker__day--outside-month {
  pointer-events: none;
  color: color(calendar-next-month-day);
}

.custom-date-picker-arrows {
  color: "#0F58D6";
}
