.table-mobile-accordion__toggle-report-id {
  flex-shrink: 0;
  width: 5rem;
  padding-right: measure(half);
}

.table-mobile-accordion__toggle-title {
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-mobile-accordion__key-value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.125rem;
  padding: measure(quarter);
  background-color: color(table-body-background-even);
  
  &:nth-child(even) {
    background-color: color(table-body-background-odd);
  }
}

.table-mobile-accordion__key {
  @include body;
}

.table-mobile-accordion__value {
  @include body-light;
}
