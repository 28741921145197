// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400&display=swap');

@font-face {
  font-family: 'skf_sans_light';
  src: url('https://fonts.skf.com/SKFSans-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'skf_sans_medium';
  src: url('https://fonts.skf.com/SKFSans-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'skf_sans_light2';
  src: url('../../fonts/SKFSans-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'skf_sans_medium2';
  src: url('../../fonts/SKFSans-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  @include body;
  background-color: color(page-background);
  color: color(text);
  font-size: 100%;
  line-height: 1.4;
  min-height: 100vh;
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  @media print {
    background-color: #ffffff;
    min-height: 0;
  }
}

.container {
  @include container;
}