.button {
  @include reset-button;
  @include body;
  position: relative;
  justify-content: center;
  height: 2.75rem;
  padding: 0 measure(full);
  color: color(button-text);
  text-transform: uppercase;
  overflow: hidden;
  transition: all 140ms;
}

.button--background-green {
  background-color: color(button-default-background);

  &:hover {
    background-color: color(button-default-hover);
  }

  &:disabled {
    background-color: color(button-toggler-disabled);
    pointer-events: none;
  }
}

.button--background-orange {
  background-color: color(button-favorite-background);

  &:hover {
    background-color: color(button-default-hover);
  }

  &:disabled {
    background-color: color(button-toggler-disabled);
    pointer-events: none;
  }
}

.button--background-red {
  background-color: color(input-border-error);

  &:hover {
    background-color: color(button-default-hover);
  }

  &:disabled {
    background-color: color(button-toggler-disabled);
    pointer-events: none;
  }
}

.button--background-dark-gray {
  background-color: color(button-toggler-background);

  &:hover {
    background-color: color(button-toggler-hover);
  }

  &:disabled {
    background-color: color(button-toggler-disabled);
    pointer-events: none;
  }
}

.button--background-gray {
  background-color: color(button-selection-background);

  &:hover {
    background-color: color(button-selection-hover);
  }

  &:disabled {
    background-color: color(button-selection-disabled);
  }
}

.button--disabled {
  background-color: color(button-toggler-disabled);
  pointer-events: none;
}

.button--has-icon svg {
  flex-shrink: 0;
  margin-right: measure(quarter);
}

.button--icon-to-right {
  flex-shrink: 0;
  padding-right: measure(half);
}

.button--wide{
  width: auto !important;
}

.button--full{
  width: 100%;
}

.button--icon-to-right svg {
  flex-shrink: 0;
  order: 2;
  margin-right: 0;
  margin-left: auto;
}

.button--icon-only {
  padding: 0;
  width: 2.8125rem;
  height: 2.8125rem;
}

.button--icon-only svg {
  margin-right: 0;
  margin-left: 0;
}

.button--text-to-left {
  justify-content: flex-start;
}

.button__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button--text-to-left.button--icon-to-right .button__text {
  padding-right: measure(quarter);
}
