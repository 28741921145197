.date-range__date-picker_label {
  color: color(text-inverted) !important;
}

.datepicker__filters {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
}

.date-picker {
  .react-datepicker__day.react-datepicker__day--disabled {
    color: gray;
    pointer-events: none;
  }
}
