.table-wrapper {
  overflow-x: auto;
}

.table {
  position: relative;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.table--layout-fixed {
  table-layout: fixed;
  min-width: 78.125rem;
}

.table__header-row {
  @include body-small;
  color: color(text-inverted);
  background-color: color(table-header-background);
}

.table__header-cell {
  width: 10rem;
  &--small {
    width: 9rem;
  }
  &_offline {
    min-width: 10rem;
  }
}

.table__header-inset {
  @include body-small;
  display: flex;
  align-items: center;
  min-height: 3.125rem;
  padding: measure(quarter) measure(half);
  color: color(text-inverted);
  width: 100%;
}

.table__header-inset--button {
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.table__header-inset--align-right {
  justify-content: flex-end;
}

.table__header-additional-information {
  margin-left: measure(quarter);
}

.table__hader-additional-information-trigger {
  @include reset-button;
}

.table__header-sort-actions {
  display: flex;
  flex-direction: column;
  margin-left: measure(quarter);
}

.table__header-sort {
  padding: 0;
  border: 0;
  color: color(table-header-icon-dimmed);
}

.table__header-sort--is-active {
  color: color(table-header-icon-active);
}

.table__body-row {
  background-color: color(table-body-background-odd);

  &:nth-child(even) {
    background-color: color(table-body-background-even);
  }

  &:hover {
    cursor: pointer;
  }
}

.table-body__row--loader-message {
  background-color: color(table-body-background-even);
}

.table__body-cell {
  position: relative;
}

.table__body-inset {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 3.125rem;
  padding: measure(quarter) measure(half);
}

.table__body-inset--align-right {
  justify-content: flex-end;
}

.table__body-inset--no-wrap {
  white-space: nowrap;
}

.table__body-inset--report_author {
  width: 11rem;
  word-break: break-all;
}

.table__body-link {
  @include reset-link;
}

.table__body-truncate-button {
  @include reset-button;
  display: block;
  color: color(link-color);
  text-align: left;
  overflow: hidden;

  &:hover {
    text-decoration: underline;
  }
}

.table__body-overlay-element {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: flex-end;
  padding-right: measure(full);
  background-color: rgba(color(table-header-hover-background), .3);
}

.table-mobile-accordion__actions .table__body-overlay-element {
  position: relative;
  top: initial;
  bottom: initial;
  left: initial;
  display: block;
  padding-right: initial;
  background-color: transparent;
}

.table__body-row--is-active .table__body-overlay-element {
  display: flex;
}

.table__body-overlay-element > button:not(:first-child):not(.table__body-overlay-element-close) {
  margin-left: measure(quarter);
}

.table-mobile-accordion__actions .table__body-overlay-element > button {
  width: 100%;
  margin-bottom: measure(quarter) !important;
  margin-left: 0 !important;

  &:not(:last-child) {
    margin-bottom: 0;
  }
}

.table__body-lock-svg {
  margin-right: measure(quarter);
}

.table__body-overlay-element-close {
  width: 1.875rem;
  height: 1.875rem;
  margin-left: measure(full);
  padding: 0;
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
}

.table__body-overlay-element-close > svg {
  width: 100%;
  height: 100%;
}

.table-mobile-accordion__actions .table__body-overlay-element-close {
  display: none;
}
