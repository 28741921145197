.tgl-container{
    display: flex;
    span{
        padding-left: 20px;
        line-height: 0.9em;
    }
}

.tgl {
	display: none;
  
	// add default box-sizing for this scope
	&,
  &:after,
  &:before,
	& *,
  & *:after,
  & *:before,
	& + .tgl-btn {
		box-sizing: border-box;
		&::selection {
			background: none;
		}
	}
  
	+ .tgl-btn {
		outline: 0;
		display: block;
		width: 3em;
		height: 1.1em;
		position: relative;
		cursor: pointer;
    user-select: none;
		&:after,
    &:before {
			position: relative;
			display: block;
			content: "";
            width: 66%;
            height: 207%;
            top: -50%;
		}
    
		&:after {
			left: -10%;
		}
    
		&:before {
			display: none;
		}
	}
  
	&:checked + .tgl-btn:after {
        left: 50%;
        background: color(green-1);
        border: solid 1px color(green-3);
	}
}

// themes
.tgl-light {
	+ .tgl-btn {
		background: #C4C4C4;
        border-radius: 2em;
        border-color: #BBBBBB;
		padding: 2px;
		transition: all .4s ease;
		&:after {
			border-radius: 50%;
            background: color(white);
            border:solid 1px #C4C4C4;
            transition: all .2s ease;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		}
	}
  
	&:checked + .tgl-btn {
        background: #CDDCAB;
        border-color: #C4C4C4;
	}
}