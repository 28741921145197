.user-bar {
  background-color: color(user-bar-background);
}

.user-bar__container {
  @include container;
  @include body;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 2.5rem;
  color: color(user-bar-text);
}

.user-bar__sign-out {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  padding: 0;
  margin-left: measure(quad);
  color: color(link-color);
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
}

.user-bar__sign-out-icon {
  margin-right: measure(quarter);
}
