@mixin media($breakpoint, $onlyScreen: false) {
  @if map-has-key($breakpoints, $breakpoint) {
    @if $onlyScreen {
      @media only screen and #{inspect(map-get($breakpoints, $breakpoint))} {
        @content;
      }
    }

    @else {
      @media #{inspect(map-get($breakpoints, $breakpoint))} {
        @content;
      }
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}
