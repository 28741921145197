$buttonMargin: measure(eighth);

.pagination {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #efefef;

  .button {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: color(white);
    color: color(nav-background);
    width: measure(quad);
    height: measure(quad);
    
    &:hover {
      cursor: pointer;
    }

    &:disabled {
      pointer-events: none;
      cursor: initial;
    }
  }
}

.pagination__prev--button {
  background: color(white);
  margin-right: $buttonMargin;
}

.pagination__next--button {
  margin-left: $buttonMargin;
  background: color(white);
}

.pagination__prev--button,
.pagination__next--button {
  svg {
    margin: 0;
  }
}

.pagination__pages-container {
  margin: 0 $buttonMargin;
  display: flex;
}

.pagination__page--button {
  margin-right: $buttonMargin;

  &:hover {
    background: color(nav-background);
    color: color(white);
  }

  &:last-child {
    margin: 0;
  }

  &.current-page, &:disabled {
    background: color(nav-background);
    color: color(white);
  }
}

.pagination__filler {
  background: color(white);
  flex-grow: 1;
}
