.case-view__header {
  @include h1;
  margin: 0;
  color: color(page-layout-text);
}

.case-view-top {
  @include container(false);
  display: flex;
  align-items: center;
  width: auto;
  margin: -#{measure(half)} -#{measure(half)} 0 -#{measure(half)};
  padding: measure(half) measure(half) 0 measure(half);
  background-color: color(background-white);

  @include media(small) {
    margin: -#{measure(double)} -#{measure(full)} 0 -#{measure(full)};
    padding: measure(double) measure(full) 0 measure(full);
  }
  
  @include media(medium) {
    margin: 0 0 measure(half) 0;
    padding: 0;
    background-color: transparent;
  }
}

.case-view__action-with-svg {
  @include reset-button;
  @include reset-link;
  margin-left: auto;
  text-transform: uppercase;
}

.case-view__action-svg {
  margin-left: measure(quarter);
}

.case-view-filter__id-box {
  margin-bottom: measure(double);
  padding: measure(half);
  background-color: color(island-aqua-background);
}

.case-view-filter__id-box-key {
  margin-bottom: measure(quarter);
  color: color(island-aqua-text);
}

.case-view-filter__id-box-value {
  @include h2;
}

.case-view-actions__top {
  margin: measure(full) 0;
  padding: measure(full) 0;
  border-top: 1px solid color(border-gray);
  border-bottom: 1px solid color(border-gray);
}

.case-view-actions__approvers {
  margin-bottom: measure(full);
  padding-bottom: measure(full);
  border-bottom: 1px solid color(border-gray);
}

.case-view-actions__top > *,
.case-view-actions__bottom > *,
.case-view-actions__approvers > * {
  width: 100%;
  
  &:not(:last-child) {
    margin-bottom: measure(quarter);
  }
}

.case-bearing-actions {
  margin-top: measure(full);
  padding-top: measure(full);
  border-top: 1px solid color(border-gray);
}

.case-bearing-actions--empty {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}

.case-bearing-actions > *,
.case-view-actions__bottom > * {
  width: 100%;
  
  &:not(:last-child) {
    margin-bottom: measure(quarter);
  }
}

.case-view-tabs {
  display: flex;
  width: 100%;
}

.case-view-tabs__action {
  @include reset-button;
  justify-content: center;
  width: 50%;
  height: 3rem;
  color: color(text);
  background-color: color(tabs-action);
}

.case-view-tabs__action--is-active {
  background-color: color(tabs-action-active);
}

.search-template-container {
  margin-bottom: measure(full);
  //padding: measure(full) 0;
}

.search-template-container > *:not(:last-child) {
  margin-bottom: measure(full);
}

.search-template-container > .button {
  width: 100%;
}

.approveai-container{
  .react-modal__body{
    h2{
      text-align:center;
    }
  }
  .react-modal__footer{
    flex-direction: row;
    justify-content: center;
    padding: measure(full);
    display: flex;
    .button{
      margin-left: measure(full);
      margin-right: measure(full);
    }
  }
}