.header-settings {
  height: 100%;
  color: color(text-inverted);

  @include media(small) {
    position: relative;
  }

  @include media(medium) {
    height: initial;
  }
}

.header-settings__toggler {
  @include reset-button;
  width: 100%;
  height: 100%;
  padding: measure(quarter);
  background-color: transparent;
  color: color(text-inverted);
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  @include media(medium) {
    background-color: transparent;
    width: auto;
    height: 4.375rem;
    margin-bottom: 0;
    padding: 0 measure(full);

    &:hover {
      background-color: color(nav-active-background);
    }
  }
}

.header-settings__toggler--is-active {
  background-color: color(nav-active-background);
}

.header-settings__actions-container {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  padding: measure(double);
  background-color: color(nav-active-background);

  @include media(small) {
    left: initial;
    width: 20rem;
  }
}

.header-settings__langugage-header {
  @include body-small;
  margin-bottom: measure(full);
  text-transform: uppercase;
}

.header-settings-language {
  width: 100%;
}

.header-settings-language__toggler {
  @include reset-button;
  width: 100%;
  padding: measure(quarter) 0;
  color: color(text-inverted);
}

.header-settings-language__globe-svg {
  margin-right: measure(quarter);
}

.header-settings-language__action-svg-arrow {
  margin-left: measure(quarter);
  transition: transform 140ms;

  @include media(medium) {
    margin-left: measure(quarter);
  }
}

.header-settings-language--is-open .header-settings-language__action-svg-arrow {
  transform: scaleY(-1);
}

.header-settings-language__content {
  display: none;
  padding-top: measure(half);
  height: 54vh;
  overflow-y: auto;
}

.header-settings-language--is-open .header-settings-language__content {
  display: block;
}

.header-settings-language__change-action {
  @include reset-button;
  width: 100%;
  height: 2.5rem;
  padding: 0 measure(double);
  color: color(text-inverted);
  background-color: transparent;
  border-bottom: 1px solid color(blue-3);

  &:first-child {
    border-top: 1px solid color(blue-3);
  }

  &:hover {
    background-color: color(nav-background);
  }
}