.header-search {
  height: 100%;

  @include media(medium) {
    position: relative;
    height: initial;
  }
}

.header-search__toggler {
  @include reset-button;
  width: 100%;
  height: 100%;
  padding: measure(quarter);
  background-color: transparent;
  color: color(text-inverted);
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  @include media(medium) {
    background-color: transparent;
    width: auto;
    height: 4.375rem;
    margin-bottom: 0;
    padding: 0 measure(full);

    &:hover {
      background-color: color(nav-active-background);
    }
  }
}

.header-search__toggle--is-active {
  background-color: color(nav-active-background);
}

.header-search__actions-container {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  width: auto;
  background-color: color(nav-active-background);
  opacity: 0;
  visibility: hidden;

  @include media(medium) {
    left: initial;
    width: 37.5rem;
  }
}

.header-search__actions-container--is-open {
  opacity: 1;
  visibility: visible;
}

.header-search__input-element {
  width: 100%;
  height: 2.75rem;
  padding: 0 measure(quad) 0 measure(full);
  background-color: transparent;
  border: none;
  color: color(text-inverted);

  @include media(small) {
    height: 4.375rem;
  }
}

.header-search__input-element::placeholder {
  color: color(search-placeholder);
}

.header-search__clear-search-input {
  @include reset-button;
  position: absolute;
  top: 50%;
  right: measure(half);
  transform: translateY(-50%);
}
