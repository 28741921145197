.exportmessage{
    width:100%;
    display:flex;
    justify-content: center;
    margin-bottom: measure(full);
    &__text{
        display:flex;
        flex-direction: row;
        align-items: center;
        margin-top: measure(half);
        svg{
            width: measure(double);
            margin: auto 0;
        }
        div{
            text-align: left;
            padding-left: measure(quarter);
        }
    }
}