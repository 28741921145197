// this are the only font-sizes used in the application. 
// If design differs please contact design to correct it or choose what is closest.
// dev: Zoran

@mixin skf_sans_light() {
  font-family: 'skf_sans_light', 'skf_sans_light2', Arial, sans-serif;
  font-weight: 300;
}

@mixin skf_sans_medium() {
  font-family: 'skf_sans_medium', 'skf_sans_medium2', Arial, sans-serif;
  font-weight: 400;
}

@mixin font-size($size) {
  font-size: font(m-#{$size});

  @include media(medium) {
    font-size: font(#{$size});
  }
}

@mixin h1() {
  @include skf_sans_light;
  @include font-size(h1);
}

@mixin h1-medium() {
  @include skf_sans_medium;
  @include font-size(h1);
}

@mixin h2() {
  @include skf_sans_light;
  @include font-size(h2);
  text-transform: uppercase;
}

@mixin h2-medium() {
  @include skf_sans_medium;
  @include font-size(h2);
  text-transform: uppercase;
}

@mixin h3-medium() {
  @include skf_sans_medium;
  @include font-size(h3);
}

@mixin body() {
  @include skf_sans_medium;
  @include font-size(body);
  line-height: 1.4;
}

@mixin body-light() {
  @include skf_sans_light;
  @include font-size(body);
  line-height: 1.4;
}

@mixin body-small() {
  @include skf_sans_medium;
  @include font-size(body-small);
  line-height: 1.4;
}

@mixin body-small-light() {
  @include skf_sans_light;
  @include font-size(body-small);
  line-height: 1.4;
}

@mixin caption() {
  @include skf_sans_medium;
  @include font-size(caption);
}

@mixin caption-light() {
  @include skf_sans_light;
  @include font-size(caption);
}

@mixin tiny() {
  @include skf_sans_medium;
  @include font-size(tiny);
}
