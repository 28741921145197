.page-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-layout__main-container {
  @include container;
  padding-top: measure(half);
  padding-bottom: measure(half);

  @include media(small) {
    // padding-top: measure(double);
    // padding-bottom: measure(double);
  }

  @include media(medium) {
    // padding-top: calc(#{measure(quad)} + #{measure(half)});
    // padding-bottom: calc(#{measure(quad)} + #{measure(half)});
  }
}

.page-layout__container--live-preview .page-layout__main-container {
  @include container(true, true);
}

.page-layout__main-container-content {
  padding: measure(full) 0;

  @include media(medium) {
    display: flex;
    align-items: flex-start;
    padding: 0;
  }
}

.page-layout__main-container--fix {
  display: flex;
}

.page-layout__filter {
  margin-bottom: measure(quad);
  min-height: 4rem;
  @include media(medium) {
    flex-shrink: 0;
    width: 20rem;
    margin-right: measure(double);
    margin-bottom: 0;
  }
}

.page-layout__filter--live-preview {
  position: relative;
  display: none;
  
  @include media(medium) {
    width: 27.8125rem;
    margin-right: measure(full);
  }
}

.page-layout__content {
  @include media(medium) {
    flex-grow: 1;
    overflow: hidden;
  }
}

.page-layout__content--live-preview {
  display:none;
  position: sticky;
  top: 15px;
  max-height: initial;
  min-height: 4rem;
  box-shadow: 0 0 10px 0 rgba(140, 140, 140, .5);
  overflow: scroll;
  
  @include media(medium) {
    max-height: calc(100vh - 30px);
  }
}

.page-layout__toggle-view {
  display: block;
}

.page-layout__header {
  @include h1;
  margin-top: 0;
  margin-bottom: measure(quad);
  color: color(page-layout-text);
}

.page-layout__sub-header {
  @include h3-medium;
  margin-top: 0;
  color: color(page-layout-text);
}

.page-layout__header--if-text {
  margin-bottom: measure(half);
}

.page-layout__text {
  @include body-light;
  margin: 0;
  color: color(page-layout-text);
}

.page-layout__content > *:not(:last-child):not(.page-layout__header) {
  margin-bottom: measure(quad);
}

.page-layout__footer-container {
  @include container;
  padding-top: measure(quad);
  padding-bottom: measure(quad);
}


.page-layout__preview-wide{
  @include container(false);
  margin: 0 auto;
  flex-direction: column;
  .page-layout__filter {
    width: 100%;
    margin: 0 auto;
    max-width: calc(88.75rem - 3.125rem - 27.8125rem);
    .form-container{
      display:none;
    }
  }
  .page-layout__content--live-preview{
    margin: 0 auto;
    max-width: calc(88.75rem - 3.125rem - 27.8125rem);
    max-height: calc(100vh - 135px);
    @include media(medium) {
      max-height: calc(100vh - 100px);
    }
    
  }
  .button-bar__container{
    
  } 
}

.page-layout__form-wide{
  @include container(false);
  margin: 0 auto;
  .page-layout__filter {
    width: 100%;
    margin: 0 auto;
    max-width: calc(88.75rem - 3.125rem);
    .form-container{
    }
  }
  .page-layout__content--live-preview{
    display:none;
  }

  .accordion__content-form{
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    .input-shell{
      
    }
    & > div{
      min-width: 48%;
      flex: 0 0 48%;
      // @include media(large){
      //   min-width: 31%;
      //   flex: 0 0 31%;
      // }
      
    }

    .componentContainer-linebreak-full{
      min-width:100%;
    }

    .componentContainer-linebreak{
      margin-right: 30%;
    }

    .componentContainer-linebreak-nospace{
      margin-bottom: 0 !important;
      h3{
        margin-bottom:0;
      }
    }

    .componentContainer-linebreak-full-nospace{
      min-width:100%;
      margin-bottom: 0 !important;
      h3{
        margin-bottom:0;
      }
    }
    

    & > div:first-of-type{
      margin-left: 0;
    }
    & > div:last-of-type{
      margin-right: 0;
    }
  }
  
}

.hide-for-small{
  display:none;
  @include media(medium) {
    display:block;
  }
}