.case-bearingquicklinks-actions{

    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: 0;

    margin: measure(full) 0;
    padding: measure(half) 0;    
    
    border-top: 1px solid color(border-gray);
    border-bottom: 1px solid color(border-gray);
    z-index: 10;
    background-color: color(gray-6);

    .button{
        margin-left: measure(quarter);
        padding: 0 measure(quarter);
        width: 2.75rem;
        flex: 0 0 auto;    
    }

    .button-selected{
        background-color: color(button-default-hover);
    }

    .chevron-right, .chevron-left{
        button{
            border:0;
            height:100%;
            padding: 0 20px;
            line-height: inherit;
            background-color: color(gray-6);
            color: color(gray-7);
            cursor: pointer;
        }
    }
    .BrainhubCarousel__arrows{
        background-color: color(gray-6);
        span{
            border-color: color(gray-7);
        }
        &:disabled{
            background-color: color(gray-6);
            span{
                border-color: color(gray-6);
            }
        }
        &:hover:enabled{
            background-color: color(gray-6);
            span{
                border-color: color(green-3);
            }
        }
    }



    & + .case-view-actions{
        .case-view-actions__top{
            margin-top:0;
            padding-top: 0;
            border-top: none;
        }
    }

    h4{
        text-transform: uppercase;
        text-align: center;
        color: color(gray-2);
        margin-top:0;
        margin-bottom:measure(half);
    }
}
