@page {
  padding: 30px 0;
  margin-top: 56px;
  margin-bottom: 100px;
}

.live-preview {
  position: relative;
  padding: measure(half);
  background-color: color(background-white);

  @include media(medium) {
    padding: measure(quad);
  }

  @media print {
    padding: 0;
  }

  .live-preview-key-value__row.scroll-indicator {
    @include preview-indicator();
  }

  .live-preview-key-value.scroll-indicator {
    .live-preview-key-value__row {
      @include preview-indicator();
    }
  }

  .scroll-indicator {
    .live-preview-key-value__row {
      @include preview-indicator();
    }

    .live-preview-list-structure__header {
      @include preview-indicator();
    }

    .live-preview__performance-level {
      @include preview-indicator();
      margin-left: auto;
      padding-left: auto;
    }
  }

  strong {
    font-weight: bold;
  }
}

.live-preview-header {
  @include caption;
  display: flex;
  justify-content: flex-end;
  color: color(header-background);

  @media print {
    display: none;
  }
}

.live-preview-header__logo {
  width: 7.375rem;
  height: 1.75rem;
}

.live-preview-footer {
  @include caption-light;
  position: absolute;
  bottom: 1.875rem;
  left: 3.125rem;
  right: 3.125rem;
  display: flex;
  align-items: center;

  @media print {
    display: none;
  }
}

.live-preview-footer__page-number {
  margin-left: auto;
}

.live-preview__header {
  @include h1-medium;
  margin-top: 0;
  display: flex;
  justify-content: space-between;


}

.live-preview__report-number-container {
  display: flex;
  flex-direction: column;
  padding: measure(half);
  background-color: color(live-preview-dark-background);
  color: color(text-inverted);

  @include media(small) {
    flex-direction: row;
  }

  @media print {
    flex-direction: row;
  }


}

.live-preview__report-number {
  @include body;
  margin: 0;
}

.live-preview__report-revision-number {
  @include body-light;
  margin-top: 0;
  margin-bottom: 0;

  @include media(small) {
    margin-left: auto;
  }

  @media print {
    margin-left: auto;
  }
}

.live-preview__general-text {
  @include tiny;
  margin-top: measure(full);

  @media print {
    break-inside: avoid;
  }
}

.live-preview__general-paragraph {
  padding-top: 0;
  padding-bottom: measure(half);
}

.live-preview-report-information__container {
  padding: measure(full) 0;
  border-bottom: 1px solid color(live-preview-border);

  &:first-child {
    padding-top: 0;
  }
}

.live-preview-report-information__container-column {
  display: flex;
  flex-direction: column;

  @include media(small) {
    flex-direction: row;
  }

  @media print {
    flex-direction: row;
  }
}

.live-preview__ussummary {
  margin-top: measure(quarter);
}

.bearing-table_container {
  width: 100%;
  margin: measure(quarter) auto;

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    tr {
      &:nth-child(even) {
        background-color: color(live-preview-row-background);
      }
    }

    th {
      text-align: left;
      white-space: nowrap;
      padding: measure(eighth) measure(eighth) measure(eighth) measure(quarter);

      &:first-child {
        width: measure(full)
      }
    }

    td {
      vertical-align: top;
      padding: measure(eighth) measure(eighth) measure(eighth) measure(quarter);

      &:first-child {
        width: measure(full)
      }
    }
  }
}

.bearing-table_container>div {
  margin: measure(quarter) measure(half);
}

.live-preview-report-information__container-column>* {
  flex-grow: 1;

  @include media(to-small) {
    flex-basis: 100%;
  }

  @include media(small) {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 50%;
  }

  @media print {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 50%;
    margin-bottom: 0;
  }
}

.live-preview-report-information__two-column {
  display: grid;
  grid-gap: measure(half);
  grid-template-columns: 1fr;

  @include media(medium) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.live-preview-report-information__row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: measure(quarter) measure(half);
  text-align: left;
}

.live-preview-report-information__key {
  @include caption;
}

.live-preview-report-information__value {
  @include caption-light;
}

.live-preview-report-information__top-margin {
  margin-top: measure(quarter);
}

.live-preview-key-value__header {
  @include body-small;
  width: 100%;
  padding: measure(quarter) measure(half);
  background-color: color(live-preview-dark-background);
  color: color(text-inverted);
  text-transform: uppercase;

  span {
    padding-left: measure(full);
  }

  @media print {
    break-inside: avoid-page;
  }
}

.inspection_ref_bda {
  margin: 0;  
  display: inline-block;
  font-weight: bold;
  color: color(nav-border);
  transition: all .2s;
  font-size: 1em;
  
  &:hover {
    cursor: pointer;
    color: color(nav-background);
  }
}

.live-preview-key-value__bearingheader {
  display: flex;
  justify-content: space-between;

  span {
    padding-left: measure(full);
    width: 100%;
  }

  .first {
    padding-left: 0;
    white-space: nowrap;
    width: auto;
  }

  .last {
    white-space: nowrap;
    width: auto;
  }
}

.live-preview-key-value__header__without_background_color {
  @include body-small;
  width: 100%;
  padding: measure(quarter) measure(half);
  color: color(text);
  text-transform: uppercase;
}

.live-preview-key-value-with-page-break-if-print__header {


  @media print {
    page-break-before: always
  }
}

.live-preview-key-value__container {
  display: flex;
  flex-wrap: wrap;
}

.live-preview-key-value__row {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;

  &:nth-child(odd) {
    background-color: color(live-preview-row-background);
  }

  @include media(small) {
    flex-direction: row;
  }

  @media print {
    break-inside: avoid-page;
    flex-direction: row;
  }
}


.live-preview-summary-conclusions {
  .live-preview-key-value__row {
    background-color: inherit;
  }
}

.live-preview-key-value__row>* {
  flex-grow: 1;
  flex-basis: 50%;
}

.live-preview-key-value__cell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: measure(quarter) measure(half);
  text-align: left;

  @include media(small) {
    flex-direction: column;
  }
}

.live-preview-key-value__key {
  @include caption;
  flex-shrink: 0;
  margin-right: measure(quarter);
}

.live-preview-key-value__value {
  @include caption-light;
  flex-grow: 1;
  white-space: pre-wrap;
  word-break: break-word;

  .valueWithSpace {
    span:last-of-type {
      padding-left: measure(half);
    }
  }
}

.live-preview-list-structure>* {
  margin-bottom: measure(half);
}

.live-preview-list-structure__header {
  @include h2-medium;
  margin: measure(half) 0;
  text-transform: none;
}

.live-preview-list-structure__item-sub-header {
  @include h2-medium;
  margin-top: 0;
  margin-bottom: measure(quarter);
  text-transform: none;
}

.live-preview-list-structure__item-content {
  @include body-small-light;
}

.live-preview-content__item {
  margin-bottom: measure(quad);
}

.live-preview-content__header {
  @include body-small;
  margin-top: 0;
  margin-bottom: measure(eighth);
}

.live-preview-content__text {
  @include body-small-light;
}

.live-preview-content__text p {
  margin: 0;
  padding: 0;
}

.live-preview-images {
  padding: measure(quarter);
  margin-bottom: measure(half);
  border: 1px solid color(live-preview-media-border);

  @media print {
    break-inside: avoid;
  }
}

.live-preview-images__headline {
  @include body;
  margin: -#{measure(quarter)} -#{measure(quarter)} measure(half) -#{measure(
 quarter)
}

;
padding: measure(eighth) measure(quarter);
background-color: color(live-preview-media-background);
}

.live-preview-images__severity {
  display: flex;
  justify-content: space-between;

  .severity {
    display: flex;

    .severity__header {
      margin-right: measure(half);
    }
  }
}

.live-preview-outercontainer--one-figure {
  width: 50%;
  margin: auto;
}

.live-preview-outercontainer--50-50 {
  width: 90%;
  margin: auto;
}

.live-preview-outercontainer--70-30 {
  width: 90%;
  margin: auto;
}

.live-preview-outercontainer--3 {
  width: 90%;
  margin: auto;
}

.layout_70 {
  width: 68% !important;
}

.layout_30 {
  width: 28% !important;
}

.layout_one-plus-three_1 {
  width: 100% !important;
}

.layout_one-plus-three_2,
.layout_one-plus-three_3,
.layout_one-plus-three_4 {
  width: 31% !important;
}

.live-preview-images__container {
  .live-preview-images__container--3 {
    margin: auto;
    width: 100%;

    .live-preview-images-item {
      width: 31%;
      page-break-inside: avoid;
      margin-top: 5px;
      margin-right: 5px;
    }
  }

  .live-preview-images__container--one-figure {
    margin: auto;
    width: 100%;

    .live-preview-images-item {
      width: 100%;
      page-break-inside: avoid;
      margin-top: 5px;
    }
  }

  :after {
    content: "";
    clear: both;
    display: table;
  }

  .live-preview-images__container--50-50 {
    margin: auto;
    width: 100%;
    margin-bottom: measure(quarter);
    page-break-inside: avoid;

    .live-preview-images-item {
      width: 48%;
      margin-left: 2%;
      float: left;
      margin-top: 5px;
    }
  }

  :after {
    content: "";
    clear: both;
    display: table;
  }

  .live-preview-images__container--one-plus-three {
    margin: auto;
    width: 100%;
    margin-bottom: measure(quarter);

    .live-preview-images-item {
      margin-top: 5px;
      margin-right: 5px;
    }
  }

  :after {
    content: "";
    clear: both;
    display: table;
  }

  .live-preview-images__container--70-30 {
    margin: auto;
    width: 100%;
    margin-bottom: measure(quarter);
    page-break-inside: avoid;

    .live-preview-images-item {
      margin-top: 5px;
      margin-right: 5px;
    }
  }

  :after {
    content: "";
    clear: both;
    display: table;
  }
}

.live-preview-images-item {
  border: 1px solid color(live-preview-media-border);
  width: fit-content;
  float: left;
}

.live-preview-images-item--more-images {
  max-width: 100%;
  width: 100%;
}

.live-preview-images-item__media {
  padding: measure(quarter);
  background-color: color(live-preview-media-background);
}

.live-preview-images-item__media-figure {
  @include caption;
}

.live-preview-images-item__media-container {
  position: relative;
}

.live-preview-images-item__media-container--more-images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: measure(half);
}

.live-preview-images-item__media-container--background-image {
  background-position: center;
  background-size: cover;
  padding-top: calc(14.375rem / 21.9375rem) * 100%;
}

.live-preview-images-item__media-container--object-fit {
  padding-top: calc(14.375rem / 21.9375rem) * 100%;
}

.live-preview-image-item__media-image {
  display: block;
  width: 100%;
  max-height: 1000px;
}

.live-preview-image-item__media-image--object-fit {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.live-preview-images-item__description {
  @include caption-light;
  padding: measure(quarter);
}

.live-preview-status-confidential {
  @include body-small;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: measure(eighth);
  text-transform: uppercase;

  &__status {
    display: flex;
    width: fit-content;
    justify-content: flex-end;
  }

  .print {
    display: none;

    @media print {
      display: block;
    }
  }

  &.single-status {
    justify-content: flex-end;
  }
}

.live-preview__ref__inspection__id {
  font-size: 1.1em;
  text-transform: none;
}

.live-preview-status-confidential__status>*:not(:last-child) {
  margin-right: measure(half);
}

.live-preview-status-confidential__confidential {
  // display: flex;
  // align-items: center;
}

.live-preview-status-confidential__confidential>* {
  margin-right: measure(eighth);
}

.key-value__row {
  display: flex;
  background-color: greenyellow;
  padding: measure(quarter);

  &:nth-child(even) {
    background-color: deepskyblue;
  }

  @media print {
    break-inside: avoid-page;
  }
}

.key-value__cell {
  flex-grow: 1;
  padding: measure(quarter);
  background-color: aquamarine;
}

.key-value__row .key-value__cell:nth-child(even) {
  background-color: green;
}

.live-preview__performance-level {
  @include body-light;
  width: 20rem;
  margin: 0 auto measure(half) auto;
  padding: measure(eighth);
  color: color(text-inverted);
  text-align: center;
  text-transform: uppercase;
}

.live-preview__performance-level--attention {
  background-color: color(orange-1);
}

.live-preview__performance-level--unacceptable {
  background-color: color(red-1);
}

.live-preview__performance-level--acceptable {
  background-color: color(yellow-1);
  color: color(text);
}

.live-preview__performance-level--good {
  background-color: color(green-1);
}

.live-preview__recommendations {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: measure(half);
  margin-top: measure(eighth);

  .recommendation-option {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      margin-right: measure(quarter);
    }
  }
}

.skip-break {
  @media print {
    break-inside: auto;
  }

  .live-preview-key-value__cell {
    @media print {
      display: block;
    }

    .live-preview-key-value__value>* {
      break-inside: avoid-page;
    }
  }
}

.live-preview__enduser {
  display: flex;

  &>div {
    width: calc(33% - 0.625rem + 0.625rem/2);
    margin-left: 0.625rem;
  }

  &>div:first-of-type {
    margin-left: 0;
  }

  .live-preview-key-value__key {
    margin-bottom: measure(quarter);
  }

  .live-preview-key-value__row {
    flex-direction: column;
  }
}


.live-preview-equipment-table {
  margin-top: measure(half);

  .scroll-indicator {
    @include preview-indicator();
  }

  table {
    width: 100%;
    border-spacing: 0;

    tr {
      background-color: color(live-preview-row-background);

      &:nth-child(even) {
        background-color: color(white);
      }

      th,
      td {
        padding: measure(eighth) measure(half);
        text-align: left;
        vertical-align: top;
      }
    }
  }
}