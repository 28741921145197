.input-files-list {
  @include body-light;
  list-style-type: none;
  padding: 0;
}

.input-files-list__item {
  margin-bottom: measure(eighth);
  padding: measure(eighth);

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: color(gray-4);
  }
}
