$indentation: 25px;
$iconWidth: 38px;

.accordion {
  margin-top: -2px;
}

.accordion + .componentContainer {
  .input-shell {
    margin-top: measure(full);
  }
  
}

.componentContainer {
  .input-shell {
    margin-top: measure(full);
  }
  .page-layout__sub-header{
    margin-top: measure(full);
  }
}


.accordion__trigger {
  display:flex;
  justify-content: left;
  height:31px;
  max-width:100vh;
  button{  
    @include reset-button;
    flex-grow: 1;
    position: relative;
    width: 90%;
    padding: 4px 0;
    color: color(text);
    text-transform: uppercase;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    span{
      overflow:hidden;
      width: auto;
      white-space: nowrap;
      padding-left: measure(half);
      padding-right: measure(half);
      text-overflow: ellipsis;
    }
    .is-invalid{
      width:12px;
      height:100%;
      &:after{
        content: '';
        position: absolute;
        top: 11px;
        margin-left:4px;
        width: 12px;
        height: 12px;
        background-image: icon(invalid);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
      }
    }
  }

 .accordion__trigger-icon{
    flex-shrink: 0;
  } 
}

.accordion__trigger-selected{
  button{
    font-weight: bold;
  }
}

.accordion--mobile-table .accordion__trigger {
  min-height: 2.75rem;
  padding-right: measure(double);
}

.accordion__content-form{
  border-top: solid 1px color(gray-3);
  border-bottom: solid 1px color(gray-3);
  margin-top:10px;
  display: inline-block;
  width: 100%;
  margin-bottom:12px;
}

.accordion__content {
  display:none;
  // transform: scaleY(0);
  // height:0px;
  // .accordion__content-form{
    
  // }
}

.accordion__content--is-open {
  display: block;

  // transition:transform 0.3s ease-out;
  // -webkit-transition: transform 0.3s ease-out;
  // -moz-transition: transform 0.3s ease-out;
  // -o-transition: transform 0.3s ease-out;

  // transform: scaleY(1);
  // height:auto;
  // transform-origin:top;
  // .accordion__content-form{
    
  // }
}

.accordion--mobile-table .accordion__content {
  padding-top: measure(quarter);
}



.accordion__content > .accordion__content-form > *:not(.accordion-menu-container):not(.accordion):not(.table-mobile-accordion__key-value) {
  margin-bottom: measure(full);
}



.accordion > .accordion__trigger {
 
}

.accordion > .accordion-trigger-container {
  
}

.accordion > .accordion__content > .accordion > .accordion__trigger {
  margin-left:30px;
}

.accordion > .accordion__content > .accordion-menu-container > .accordion >  .accordion-trigger-container {
  margin-left:30px;
}

.accordion > .accordion__content > .accordion > .accordion__content > .accordion > .accordion__trigger {
  margin-left:60px;
}

.accordion > .accordion__content > .accordion-menu-container > .accordion > .accordion__content > .accordion > .accordion__trigger {
  margin-left:60px;
}

.accordion > .accordion__content > .accordion > .accordion__content > .accordion > .accordion__content > .accordion > .accordion__trigger {
  margin-left:90px;
}

.accordion > .accordion__content > .accordion-menu-container > .accordion > .accordion__content > .accordion > .accordion__content > .accordion > .accordion__trigger {
  margin-left:90px;
}

.accordion > .accordion__content > .accordion-menu-container > .accordion > .accordion__content > .accordion > .accordion__content > .accordion-menu-container > .accordion > .accordion-trigger-container {
  margin-left:90px;
}

.accordion-menu-container{
  

  //position: relative;
  .accordion-trigger-container{
    padding-right:30px;
    position: relative;
    .page-layout__form-wide & {
      display:flex;
    }
  }

  .accordion__content &:last-child {
    
  }
  
  .accordion-menu{
    width:38px;
    height:20px;
    margin-left: -56px;
    position: absolute;
    right:0;
    top:4px;
    cursor: pointer;
    border:0;

    .page-layout__form-wide & {
      position:inherit;
      margin-left: 12px;
      top:7px;
    }
    .burger{
      margin: 0 auto
    }
    button{
      width:38px;
      height: 20px;
      padding: 0;
    }
    .bearing-add-button{
      background-color: color(green-1);
      border-radius: 10px;
      cursor: pointer;
      &.is-open{
        z-index:12;
      }
    }
  }
  .accordion-menu-content{
    background-color: color(green-1);
    flex-direction:column;
    position: absolute;
    right:0;
    top:0;
    border-radius: 10px;
    z-index: 11;
    width:auto;
    height: auto;
    .page-layout__form-wide & {
      right:auto;
      left:0;
    }
    .navigation__action {
      width: 100%;
      text-align: right;
      height:27px;
      &:last-of-type{
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      &:first-of-type{
        margin-top:20px;
      }
    }

    .disabled{
      color: color(gray-3);
      cursor:not-allowed;
      &:hover{
        background-color: transparent;
      }
    }
  }
}

.accordion{
  .loader-container{
    position: relative;
    background-color: color(gray-4);
    height: 50px;
  }
}